import ThemeProvider from 'react-bootstrap/ThemeProvider'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { useEffect, useState } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

const ROOM_ID = '994304'
const UID = '2093232'
const MAX_PAGE_SIZE = 30

const LEVELS = [1, 2, 3]
const LEVELS_NAMES = {
	1: '总督',
	2: '提督',
	3: '舰长',
}
const LEVEL_COLORS = {
	1: 'rgb(250, 103, 67)',
	2: 'rgb(171, 64, 250)',
	3: 'rgb(80, 120, 255)',
}
const PATREON_WEIGHTS_DEFAULT = {
	1: 20,
	2: 10,
	3: 1,
}

function corsProxied(url: string): string {
	return `https://corsproxy.io/?${encodeURIComponent(url)}`
}

function App() {
	const [patreons, setPatreons] = useState([])
	const [patreonWeights, setPatreonWeights] = useState(PATREON_WEIGHTS_DEFAULT as any)
	const [prizes, setPrizes] = useState([])
	const [editingPrize, setEditingPrize] = useState({ name: '', amount: 1 })
	const [drawResults, setDrawResults] = useState([])

	function addEditingPrize() {
		setPrizes([...prizes, { ...editingPrize, nonce: Date.now() }].sort((a, b) => a.amount > b.amount))
	}

	function deletePrize(dp) {
		const _prizes = Object.assign([], prizes)
		_prizes.splice(_prizes.findIndex(p => p.nonce === dp.nonce), 1)
		setPrizes(_prizes)
	}

	function drawLotteries() {
		if(prizes.length === 0) {
			alert('请先填写奖品')
			return
		}

		let tickets = [], results = []

		for(let p of patreons) {
			for(let i = 0; i < patreonWeights[p.guard_level]; i++) {
				tickets.push(p.uid)
			}
		}

		for(let prize of prizes) {
			for(let i = 1; i <= prize.amount; i++) {
				const straw = tickets[Math.floor(Math.random() * tickets.length)]
				tickets = tickets.filter(t => t !== straw)
				results.push({
					prize: prize.name,
					seq: i,
					patreonId: straw,
				})
			}
		}

		setDrawResults(results)

		return results
	}

	useEffect(() => {
	(async () => {
		let page = 1, pageCount = 2, results = []
		while(page <= pageCount) {
			const fetched = await fetch(corsProxied(`https://api.live.bilibili.com/xlive/app-room/v1/guardTab/topList?roomid=${ROOM_ID}&ruid=${UID}&page_size=${MAX_PAGE_SIZE}&page=${page}`))
			const result = await fetched.json()
			pageCount = result.data.info.page
			if(page === 1) {
				results.push(...result.data.top3)
			}
			results.push(...result.data.list)
			page += 1
		}
		setPatreons(results)
	})()
	}, [])

	return (
	<ThemeProvider>
		<img src='/logo512.png' alt='叹崽子的头像' style={{
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: -1,
			width: '10em',
		}} />
		<Container>
			<Row>
				<Col>
					<h1>叹崽子的抽奖小工具</h1>
				</Col>
			</Row>
			<Row>
				<Col sm>
					<Form>
						<legend>抽奖条件设置</legend>
						{ LEVELS.map(l => <Form.Group key={`drawing-cond-weights-${l}`}>
							<Form.Label>{`${LEVELS_NAMES[l]}权重`}</Form.Label>
							<Form.Control type='number' value={patreonWeights[l]}
							onChange={e => setPatreonWeights({ ...patreonWeights, [l]: parseInt(e.target.value) })} />
						</Form.Group>) }
						<legend>奖品设置</legend>
						<Row>
							<Col>
								<Form.Label>名称</Form.Label>
								<Form.Control value={editingPrize.name} onChange={e => setEditingPrize({ ...editingPrize, name: e.target.value})}/>
							</Col>
							<Col>
								<Form.Label>数量</Form.Label>
								<Form.Control type='number' value={editingPrize.amount} onChange={e => setEditingPrize({ ...editingPrize, amount: parseInt(e.target.value)})}/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button onClick={addEditingPrize}>添加</Button>
							</Col>
						</Row>
						<Row>
							<Col>根据奖品数量自动排序，数量少的为高等奖，数量多的为低等奖</Col>
						</Row>
						<Row>
							<Col>
								<Table>
									<tbody>
									{ prizes.map(p => <tr key={`prizes-${p.name}-${p.nonce}`}>
										<td>{ p.amount }</td>
										<td>{ p.name }</td>
										<td>
											<Button onClick={() => deletePrize(p)}>删除</Button>
										</td>
									</tr>) }
									</tbody>
								</Table>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button onClick={() => setPrizes([])}>清空</Button>
							</Col>
						</Row>
					</Form>
				</Col>
				<Col md>
					<Row>
						<Button onClick={() => { drawLotteries() } }>抽</Button>
					</Row>
					<Row>
						<Col>
							<Table>
								<tbody>
								{ drawResults.length !== 0 && drawResults.map(r => <tr>
									<td style={{ color: LEVEL_COLORS[patreons.find(p => p.uid === r.patreonId).guard_level] }}><a href={`https://space.bilibili.com/${r.patreonId}`}>{ patreons.find(p => p.uid === r.patreonId).username }</a></td>
									<td>{ r.prize } #{r.seq}</td>
								</tr>) }
								</tbody>
							</Table>
						</Col>
					</Row>
				</Col>
				<Col sm>
					<PatreonsList patreons={patreons} />
				</Col>
			</Row>
		</Container>
	</ThemeProvider>
	)
}

function PatreonsList({ patreons }) {
	return (
	<fieldset>
		<legend>舰长们</legend>
		<p>{ patreons.length === 0 ? '正在获取舰长数据' : `总督：${patreons.filter(p => p.guard_level === 1).length} 提督：${patreons.filter(p => p.guard_level === 2).length} 舰长：${patreons.filter(p => p.guard_level === 3).length}` }</p>
		<Table>
			<tbody>
			{ patreons.map(p => <tr key={`patreons-${p.uid}`}>
				<td style={{
					color: LEVEL_COLORS[p.guard_level]
				}}>{ LEVELS_NAMES[p.guard_level] }</td>
				<td><a href={`https://space.bilibili.com/${p.uid}`} target='_blank' rel='noreferrer'>{ p.username }</a></td>
			</tr>) }
			</tbody>
		</Table>
	</fieldset>
	)
}

export default App
